import {Chart} from "react-google-charts";

function Grafico(props){
const options = {
    legenda: props.legenda ? {} : {position: "none"},
    series: {
        0: { color: '#F69000' },
        1: { color: 'orange' },
        2: { color: '#f1ca3a' },
        3: { color: '#6f9654' },
        4: { color: '#1c91c0' },
        5: { color: '#43459d' },
    },
    colors: ['#290ED7', '#F69000', 'gray', '#f3b49f', '#f6c7b6'],
    pieHole: 0.4 
}
 return <>
    <div className="titulograficos">
    <h5>{props.titulo}</h5>
    </div>
    <Chart chartType={props.chartType}
            data={props.dados}
            width="100%"
            height={props.proporcao}
            options={options}
            legenda/>
 </>
}

export default Grafico;