import React from 'react';

function Banner(){
    return <section id="banner">
        <div className="container">
            <div className="row">

                <div className="col-lg-7">
                    <h1>Já imaginou poder registrar e controlar a saude da sua edificação?</h1>
                    <h4>Pred Fácil, um aplicativo simples, prático e objetivo.</h4>
                    <h2>(62) 4016-1007</h2>
                   

                    <a href="https://api.whatsapp.com/send/?phone=%2B556240161007&text=Ol%C3%A1%21&type=phone_number&app_absent=0" target="_blank"><button type="button" className="btn btn-light btn-lg btn-banner">Saiba mais</button></a>
                     <a href= "app/login" ><button aria-current="page" type="button" className="btn btn-outline-light btn-lg btn-banner">Área do cliente</button> </a>
                     
                </div>

                <div className="col-lg-5">
                    <img src="Images/logoprincipal.png" />
                </div>
            </div>
        </div>
    </section>;
  }
export default Banner;