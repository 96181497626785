import "./style.css";

function Usuario(props){
  return <div className="col-12 border-bottom">
  
      <div className="d-flex justify-content-between align-items-center">
          
              <div>
              
                 <span className="texto-nome">{props.nome} </span>
                 
                 <small className="d-inline-block texto-grupo"> {props.nomegrupo}</small>
                 <small className="d-block text-medium">{props.email}</small>
              </div>
              
              <div>
                  <button className="btn btn-principal m-3 m-2">Editar</button>
                  <button className="btn btn-principal m-2">Excluir</button>
  
              </div>
              
      </div>    
  </div>
  
  }
  export default Usuario;