
import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from '../../../arquivos/logo.bmp';
import Fundo from '../../../arquivos/logofundo.png';
import Minilogo from '../../../arquivos/logopred.png';
import "./style.css";

import SaltPassword from '../../../services/md5';
import api from "../../../services/api";

function Login(){

  const [nome, setNome] = useState('');
  const [senha, setSenha] = useState('');
  const [sucesso, setSucesso] = useState('');

  function ProcessaLogin(e){
    e.preventDefault();
    
    setSucesso('');


    api.post('v1/usuario/login', {
      nome: nome,
      senha: senha
    })
    .then(response => {
      console.log(response);
      setSucesso('S');
    })
    .catch(err => {
      console.log(err);
      setSucesso('N');
    })
  }

  return <div className="row ">
    <div className=" div-acesso col-sm-6 d-flex justify-content-center align-items-center text-center">
      <form className="form-login mt-5 form-acesso">

        <img className="mb-2 logo-login" src={Logo} alt="PredFácil" />

        <img className="mb-2" src={Minilogo} alt="PredFácil" />
          <b>
            <span className="mb-4">Acessar Conta</span>
          </b>
        <div className="form-floating">
          <input type="text" onChange={(e) => setNome(e.target.value)} className="form-control" id="floatingInput" placeholder="Nome usuario" />
          <label For="floatingInput">Usuario de acesso</label>
        </div>

        <div className="form-floating">
          <input type="password" onChange={(e) => setSenha(e.target.value)} className="form-control" id="floatingInput" placeholder="Senha" />
          <label For="floatingInput">Senha</label>
        </div>
        
        <button onClick={ProcessaLogin} className="btn-lg w-100 btn-principal">Acessar</button>

        {sucesso === "N" ? <div className="alert alert-danger mt-2" role="alert">Usuario ou senha invalidos</div> :null }
        


        <div className="mt-5">
          <span> Não tem uma conta?</span><br />
          <Link to="https://api.whatsapp.com/send/?phone=%2B556240161007&text=Ol%C3%A1%21&type=phone_number&app_absent=0">
            Entre em contato agora mesmo.
          </Link>

        </div>

      </form>
    </div>
      <div className="col-sm-6 px-0 d-none d-sm-block">
        <img className="fundo-login" src={Fundo} alt="PredFácil" />
      </div>
 
  </div>
}     

export default Login; 