import logo from '../../../arquivos/logo1.bmp';
import './style.css';
import { Link } from 'react-router-dom';

function Menu(){
    
    function openSidebar(){
        const evento = new CustomEvent('openSidebar');
        window.dispatchEvent(evento);
    }

    return <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light ps-3 pe-3">
        <div className="container-fluid fundo-menu">
            <a className="navbar-brand" href="../"><img className="mt-1" src={logo} /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-label="Recipient's username" aria-describedby="button-addon2" >
                <span className="navbar-toggler-icon"></span> 
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                
                <div className="me-auto mt-1">
                    <div className="input-group">
                        <input type="search" class="form-control" placeholder="Oque deseja fazer?" aria-label="Search" />
                        <button className="btn btn-warning" type="button" id="button-addon2"><i className="fas fa-search"></i> Buscar</button>
                    </div>
                </div>

                <div className='mt-1'>
                    <button className='btn btn-outline-warning me-3 corbotao'><i className='fas fa-map-marker-alt' /> Condominio Cora</button>
                    {
                    //<button className='btn btn-outline-danger me-3'><i className='fas fa-sign-in-alt'> Acessar</i></button>
                    }
                

                    <div className='btn-group'>
                        <button type='button' className='btn btn-outline-warning me-3 dropdown-toggle corbotao' data-bs-toggle="dropdown" aria-expanded="false">
                            <i className='fas fa-user'></i>
                        </button>
                        <ul className='dropdown-menu'>
                            <li><Link to="/usuarios" className="dropdown-item">Usuarios</Link></li>
                            <li><Link to="/condominio" className="dropdown-item">Condominio</Link></li>
                            <li><Link to="/configuracoes" className="dropdown-item">Configurações</Link></li>
                            <li><hr className='dropdown-divider'/></li>
                            <li><Link to="/sair" className="dropdown-item">Sair</Link></li>

                        </ul>

                    </div>
                    <Link to="/notificacoes">
                    <button onClick={openSidebar} className='btn btn-outline-warning me-3 corbotao'>
                    <i class="fas fa-light fa-comment"></i> Notificações</button>
                    </Link>
                </div>
            </div>
        </div>
    </nav>
}

export default Menu;