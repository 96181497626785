import "./style.css"
import Logo from '../../../arquivos/logo1.bmp';
import {Link, useFetcher, useNavigate, useParams} from "react-router-dom";

function Apresentacao(){
const {codcliente} = useParams();
return <>
   <div>
    <img className="m-3" src={Logo} alt="PredFácil" />
<img className="imgcondominio" src={"https://predfacil.s3.sa-east-1.amazonaws.com/"+codcliente+"LOGO.jpg"} alt="Condominio" />
    </div>

</>
}

export default Apresentacao;