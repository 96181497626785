import Menu from "../../componentes/principal/menu";
import Mensagens from "../../componentes/notificacoes/mensagens";
import "./style.css";

function Notificacoes(){
    return <div className="container-fluid mt-page notificacoes"> 
    <Menu/>
    <div className="row col-lg-6 offset-lg-3 ">
    <div className=" col-12 mt-2 d-flex justify-content-between">      
      <span className = "fw-semibold m-2"><i className="fas fa-light fa-comment"></i> Mensagens</span>
       <button className="btn btn-sm btn-principal"> Enviar nova mensagem</button>
    </div>
    {
      [1,2,3].map(mensagem =>{
        return <Mensagens remetente="Pablo Borges" 
           mensagem="Olá, seja muito bem vindo ao sistema Pred Facil, com ele você vai conseguir gerenciar suas edificações com eficiencia e simplicidade!" 
           data_hora="01-05-2023 23:55" estado = "Envio"/>
     })
        
    }
    </div>
    </div>
}

export default Notificacoes;