import React from 'react';
import Menu from './componentes/menu';
import Banner from './componentes/banner';
import Features from './componentes/features';
import Precos from './componentes/precos';
import Baixar from './componentes/baixar';
import Footer from './componentes/footer';
import Carrossel from './componentes/carrossel';


function Site(){
    return <div>
      <Menu/>
      <Banner/>
      <Features/>
      <Carrossel/>
      <Precos/>
      <Baixar/>
      <Footer/>


    </div>;
  }

export default Site;

  