import {Chart} from "react-google-charts";

function Grafico(props){
const options = {
    legenda: props.legenda ? {} : {position: "none"}
    
}
 return <>
    <div className="titulograficos">
    <h5>{props.titulo}</h5>
    </div>
    <Chart chartType={props.chartType}
            data={props.dados}
            width="100%"
            height={props.proporcao}
            options={options}
            legenda/>
 </>
}

export default Grafico;