import './style.css';


function Funcoes(props){
    return <div className='funcoes col-sm-8 col-lg-3'>
    
    <div className='row'>
        <div className='col-3'>
            <img className='img-funcoes' src={props.icone} alt="esbelecimento" /><br />
        </div>
        <div className='col-9 mt-2 ps-1'>
            <span className='text-center fw-medium'>{props.nome}</span>
        
        </div>
    </div>
    
    </div>
}

export default Funcoes;