import React from 'react';

function Features(){
    return <section id="features">
        <div className="container">
            <div className="row">

                <div className="col-lg-4 feature-box">
                    <i class="icon fas fa-heart fa-5x"></i>
                    <h3>Fácil de usar</h3>                    
                    <p>O aplicativo possui uma interface muito simples e fácil de utilizar.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i class="icon fas fa-globe-americas fa-5x"></i>
                    <h3>Em qualquer lugar</h3>
                    <p>Gerencie e confira se tudo está correndo bem e de forma eficiente onde quer que você esteja.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i class="icon fas fa-columns fa-5x"></i>
                    <h3>Organização é tudo</h3>
                    <p>Relatórios detalhados e de fácil acesso sobre tudo que ocorre no seu condomínio.</p>
                </div>
                       
            </div>
        </div>
    </section>;
  }

export default Features;