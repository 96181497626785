import Login from './app/paginas/login/index';
import Principal from './app/paginas/principal/index';
import Notificacoes from './app/paginas/notificacoes/index';
import Usuarios from './app/paginas/usuarios';
import Site from './site/Site';
import Dashbord from "./app/paginas/dashbord/dashbord";

import { BrowserRouter, Routes, Route } from 'react-router-dom';

function Rotas(){
return <>
    <BrowserRouter>
        <Routes>
            <Route exact path='/' element={<Site/>}/>

            <Route exact path='/app/login' element={<Login/>}/> 

            <Route exact path='/app/principal' element={<Principal/>}/> 

            <Route exact path='/app/notificacoes' element={<Notificacoes/>}/> 
            
            <Route exact path='/app/usuarios' element={<Usuarios/>}/>
 
            <Route exact path='/app/graficos/:codcliente' element={<Dashbord/>}/>

        </Routes>
    </BrowserRouter>
</>
}

export default Rotas;