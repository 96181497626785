import Menu from "../../componentes/principal/menu";
import Funcoes from "../../componentes/principal/funcoes";
import Iconeusuario from "../../../arquivos/iconeusuario.png";
import Logopred from "../../../arquivos/logopred.png";
import { Link } from "react-router-dom";
import "./style.css";


function Principal(){
    return <div className="principal">
        <Menu/>
            <div className="container mt-page ">
                <div className='row justify-content-center '>
                <div className="row ">
                
                    <span className = "fw-medium principal-cabecalho text-center"> <img src={Logopred}  /> Bem vindo ao PredFacil</span>
                
                    
                    <span className = "fw-light text-left"> Selecione uma opção para começar:</span>

                    

                </div>
                <Funcoes nome = " Usuarios" icone = {Iconeusuario} />
                <Funcoes nome = " Condominio" icone = {Iconeusuario}/>
                <Funcoes nome = " Fornecedores" icone = {Iconeusuario}/>
                <Funcoes nome = " Personalizar Inspeções" icone = {Iconeusuario}/>
                <Funcoes nome = " Controle de Contas" icone = {Iconeusuario}/>
                <Funcoes nome = " Equipamentos" icone = {Iconeusuario}/>
                <Funcoes nome = " Relatorios" icone = {Iconeusuario}/>

            </div>
        </div>
    </div>
}

export default Principal;