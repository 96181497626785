import { useEffect, useState } from "react";
import api from "../../../services/api";
import Grafico from "../../componentes/grafico/grafico";
import "./style.css";
import Apresentacao from "./apresentacao";
import {Link, useFetcher, useNavigate, useParams} from "react-router-dom";
import Minilogo from '../../../arquivos/logopred.png';


function Dashbord(){
    const [dados_inspecao, setDadosInspecao] = useState([]);

    const [dados_respostas, setDadosRespostas] = useState([]);

    const [dados_planoacao, setDadosPlanoAcao] = useState([]);

    const [dados_saude, setDadosSaude] = useState([]);

    const [dados_evolucao, setDadosEvolucao] = useState([]);

    const {codcliente} = useParams();
  
    function GraficoInspecao(){
        api.get('/grafico/inspecao/'+codcliente)
        .then((retorno) => {
            setDadosInspecao(retorno.data);
        })
        .catch((err) =>{
            console.log(err);
        });

    }

    function GraficoSaude(){
        api.get('/grafico/saude/'+codcliente)
        .then((retorno) => {
            setDadosSaude(retorno.data);
        })
        .catch((err) =>{
            console.log(err);
        });


    }

    function GraficoRespostas(){
        api.get('/grafico/respostas/'+codcliente)
        .then((retorno) => {
            setDadosRespostas(retorno.data);
        })
        .catch((err) =>{
            console.log(err);
        })
    }

    function GraficoPlanoAcao(){
        api.get('/grafico/planoacao/'+codcliente)
        .then((retorno) => {
            setDadosPlanoAcao(retorno.data);
        })
        .catch((err) =>{
            console.log(err);
        })
    }

    function GraficoEvolucao(){
        api.get('/grafico/evolucao/'+codcliente)
        .then((retorno) => {
            setDadosEvolucao(retorno.data);
        })
        .catch((err) =>{
            console.log(err);
        })
    }    
    
    function MontarGraficos(){
        GraficoInspecao();
        GraficoRespostas();
        GraficoPlanoAcao();
        GraficoSaude();
        GraficoEvolucao();
    }

    useEffect(() => {
        MontarGraficos()
    }, []);


    function SalvarDadosUsuario(id, nome, email, token){
        localStorage.setItem('sessionId', id);
        localStorage.setItem('sessionNome', nome);
        localStorage.setItem('sessionEmail', email);
        localStorage.setItem('sessionToken', token);
    }
   

return <>

        <Apresentacao cliente={codcliente}/>
   
        <div className="container-fluid mt-page">
            <div className="ms-4 d-flex justify-content-between pb-5">
                <h3 className="text-secondary">Acompanhe a Saúde do seu Condomínio</h3>
                <button className="btn btn-secondary me-4" onClick={MontarGraficos}>Visualizar
                <img className="imglogomini" src={Minilogo} alt="Pred"/>
                </button>     
            </div>

            <div className="row">
                    <div className="col-md-4 p-1">
                        <Grafico titulo=" Inspeções"
                                chartType="PieChart"
                                dados={dados_inspecao}
                                legenda={true}
                                proporcao = "300px"   
                        />
                    </div>
                  
                    <div className="col-md-4 p-1">
                        <Grafico titulo= "Itens Verificados"
                                chartType= "PieChart"//"Bar"
                                dados={dados_respostas}  
                                legenda={true} 
                                proporcao = "300px"   
                                
                        />
                    </div>

                    <div className="col-md-4 p-1">
                        <Grafico titulo="Ações já Tomadas"
                                chartType= "PieChart"    //"Line"
                                dados={dados_planoacao}
                                legenda={true}   
                                proporcao = "300px"   
                        />
                    </div>

                    <div className="col-md-12 p-1">
                        <Grafico titulo="Saúde do Condomínio"
                                chartType="PieChart"
                                dados={dados_saude}
                                legenda={true}   
                                proporcao = "500px"   

                        />
                    </div>

                    <div className="col-md-12 p-1">
                        <Grafico titulo="Nº de Inspeções X Tempo"
                                chartType="ColumnChart"
                                dados={dados_evolucao}
                                legenda={true}   
                                proporcao = "300px"   

                        />
                    </div>
                </div>
           

        </div>

    </>
}

export default Dashbord;
