
import "./style.css";
function Mensagens(props){
    return <div className="row mensagem m-3">
            <h5 className="fw-semibold">{props.estado}: {props.remetente}</h5>
            <small className="fw-medium">{props.mensagem}</small>
            <h6 className="text-end fw-light">Data/Hora {props.estado}: {props.data_hora}</h6>

    </div>
}

export default Mensagens;