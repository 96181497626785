import React from 'react';

function Carrossel(){
    return <section id="carrossel">
        <div className="container">

        <div id="carouselExampleInterval" className="carousel carousel-dark  slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="10000">
                        <img src="Images/carroceu1.png"/>
                        <h4>Um prédio com atrasos na vistoria e na manutenção, pode causar muito mais do que apenas um visual nada atraente para seus frequentadores.</h4>
                                        
                      
                    </div>      

                    <div className="carousel-item" data-bs-interval="10000">
                        <img src="Images/carroceu3.png"/>
                        <h4>A inspeção predial é altamente recomendada pela norma brasileira NBR 16747/2020 e pela norma do Instituto Brasileiro de Avaliações e Perícias de Engenharia (IBAPE). .</h4>
                                        
                    </div>      

                    <div className="carousel-item" data-bs-interval="10000">
                        <img src="Images/carroceu4.png"/>
                        <h4>A realização de manutenções e inspeções preventivas garante:</h4>
                        <h6>Economia: Evite gastos desnecessários com um planejamento estratégico das manutenções preventivas.</h6>
                        <h6>Segurança: Prevenção de acidentes através de inspeções prediais.</h6>
                        <h6>Durabilidade: Preserva a garantia da edificação quando aliada ao manual do síndico para melhor orientar as boas práticas das atividades de manutenção.</h6>
                                        
                    </div>      

                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        
        </div>
    </section>;
  }

export default Carrossel;