import React from 'react';

function Footer(){
  var ano = new Date().getFullYear(); 

    return <section id="footer">    
      <div>
            <ul className="list-unstyled list-inline social text-center">
                <li className="list-inline-item "><a href="https://pt-br.facebook.com/predfacil" target="_blank"><i className="fa fa-facebook fa-2x corfooter"></i></a></li>
                <li className="list-inline-item"><a href="https://api.whatsapp.com/send/?phone=%2B556240161007&text=Ol%C3%A1%21&type=phone_number&app_absent=0" target="_blank"><i className="fa fa-whatsapp fa-2x corfooter"></i></a></li>
                <li className="list-inline-item"><a href="https://www.instagram.com/predfacil/" target="_blank"><i className="fa fa-instagram fa-2x corfooter"></i></a></li>						
                <li className="list-inline-item"><a href="#" target="_blank"><i className="fa fa-envelope fa-2x corfooter"></i></a></li>
            </ul>
	    </div>      
      <p className="textofooter" >Telefone (62) 4016-1007</p>
      <p className="textofooter" >Rua 122, nº 252 Qd F43A Lt 27 sala 02 - Setor Sul - Goiânia/GO</p>
      <p className="textofooter" >      
      <a className='col-11 col-md-3 col-lg-2 mt-3'  href="Downlaods\Politica_Privacidade.pdf" download="Politica_Privacidade_PredFacil.pdf" target="_blank"> 
        <b>Consulte aqui nossa Politica de Privacidade</b>
      </a>
      </p>
      <p className="textofooter" >© Desenvolvido por Pred Fácil - {ano}</p>

    </section>;
  }
export default Footer;