import Menu from "../../componentes/principal/menu";
import Usuario from "../../componentes/usuarios/usuario";
import "./style.css";
import api from "../../../services/api";
import { useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";

function Usuarios(){

    const[dados_usuarios, SetDadosUsuarios] = useState([]);

    function ListaUsuarios(){
        api.get('/usuarios/')
        .then((retorno) => {
          SetDadosUsuarios(retorno.data);
        })
        .catch((err) =>{
          alert(err);
        })    
      
    }



    useEffect(() => {
        ListaUsuarios();
    }, []);

  return <div className="container-fluid mt-page usuario">
    <Menu/>
        <div className="row col-lg-6 offset-lg-3 ">
            <div className=" col-12 mt-2 d-flex justify-content-between">
            <span className = "fw-semibold m-2 usuario-cabecalho "><i className="fas fa-user"></i> Cadastro de Usuarios</span>
                 <button className="btn btn-sm btn-principal"> Adicionar Novo Usuario</button>
            </div>
            <div className="row mt-5">
                {dados_usuarios.map(usuarios =>{
                    return <Usuario nome = {usuarios.nome} nomegrupo = {usuarios.nomegrupo} email = {usuarios.email}/>

                })}

            </div>

        </div>

  </div>

}

export default Usuarios;