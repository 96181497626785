import React from 'react';

function Precos(){
    return <section id="preco">
        <div className="container">

            <div className="row text-center">
                <div className="titulo">
                    <h1></h1>
                    <p></p>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header"><h1>Investimentos</h1></div>
                        <div className="card-body">
                            <h2>R$ 1.800,00<small className="text-muted"> /Taxa de adesão</small></h2>
                            <h2>R$ 380,00<small className="text-muted"> /Manutenção Mensal</small></h2>
                            <p>Atualizações periodicas</p>
                            <p>Suporte via telefone e email</p>
                            <a href="https://api.whatsapp.com/send/?phone=%2B556240161007&text=Ol%C3%A1%21&type=phone_number&app_absent=0" target="_blank"><button className="btn btn-lg btn-outline-warning btnpreco">Assinar Agora</button></a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>;
  }

export default Precos;