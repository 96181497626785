import React from 'react';

function Menu(){
    return <nav className="navbar fixed-top navbar-expand-lg navbar-light">

    <div className="container">
            
        <a className="navbar-brand" href="#">
          <img className='ImagemMenu' src="Images/logomenu.png" alt=""  />
        </a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">

          <ul className="navbar-nav ms-auto">


            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#banner">Início</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#features">Objetivo</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#carrossel">Por que usar</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#preco">Planos e Preços</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#baixar">Downloads</a>
            </li>

            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#footer">Contato</a>
            </li>
            <li className='nav-item'>
            <a className="nav-link" aria-current="page" href="login.jsx">
            <a href= "app/login" ><button className='btn btn-outline-warning me-3 corbotao'><i className='fas fa-sign-in-alt'> Área do Cliente</i></button></a>

            </a>
            </li>
          </ul>
        </div>    
      
    </div>
  </nav>;
  }

export default Menu;