import React from 'react';
import './style.css';

function Baixar(){
    return <section id="baixar">
        
        <div className='downloads row col-12'>
          <div className="card-header">
          <h3>Downloads</h3>
          </div>
          <a className='col-11 col-md-3 col-lg-2 mt-3' href="Downlaods\PredFacil_install.zip" download="predFacil_install.zip" target="_blank"> 
            <img className='imagemwindows ' src="https://bk.ibxk.com.br/2014/11/13/13121504851344.jpg" alt="Download para Windows" />
            <br />
            <b>Baixe agora para Windows</b>
            <br />
          </a>
          <a className='col-11 col-md-3 col-lg-2 mt-3'  href="Downlaods\PredFacil.apk" download="predFacil.apk" target="_blank"> 
            <img className='imagemandroid' src="Images\qrcode.png" alt="Download para Android" />
            <br />
            <b>Baixe agora para Android </b>
            
          </a>
          <a className='col-11 col-md-3 col-lg-2 mt-3'  href="Downlaods\PredFacil64.apk" download="predFacil64.apk" target="_blank"> 
            <img className='imagemandroid' src="Images\qrcode64.png" alt="Download para Android 64" />
            <br />
            <b>Baixe agora para Android 64x </b>
            
          </a>

        <p/>
        </div>
    </section>;
  }

export default Baixar;